@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style: italic;
  font-weight: 400;
}

$body-color: #000000;
$headings-color: #27203B;
$brandDark: #FF5C37;
$brandMid: #FF9448;
$lightGrey: #F6F4F2;
$indigo: #27203B;

$font-family-sans-serif:  "proxima-nova", Arial, Helvetica, sans-serif;
$headings-font-weight: 600;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$theme-colors: (
  "primary": $brandDark,
  "secondary": #F6F4F2,
  "danger": #D66445
);


$input-border-color: $lightGrey;
$btn-font-weight: 600;
$btn-border-width: 1px;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 2rem;
input:hover { 
  border-color: $indigo;
}


$code-color: map-get($theme-colors, "primary");

$label-margin-bottom: .2em;
label { 
  font-weight:600;
  color: $indigo
}

.nav-item {
  font-weight:600;
  .active { 
    color: map-get($theme-colors, "primary");
  }
}

@import "~bootstrap/scss/bootstrap"